import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Import AngularFire modules
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

// ReactiveForms
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
///import { HeaderComponent } from './pages/secure/header/header.component';
//import { FooterComponent } from './pages/secure/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
//import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { TooltipsModule } from 'ionic-tooltips';

@NgModule({
    declarations: [AppComponent ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
       // NgxIntlTelInputModule,
        IonicModule.forRoot({ mode: 'ios' }),
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
       TooltipsModule.forRoot(),
     //   BsDropdownModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase), // Initialize Firebase
        AngularFireAuthModule, // Import Firebase Auth module
        ],
        exports: [
           // HeaderComponent,  // Export it so other modules can use it
           // FooterComponent
          ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]  // Add this line

})
export class AppModule { }

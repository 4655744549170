import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cartItems = new BehaviorSubject<any[]>([]);

  private baseUrl = environment.baseUrl + 'api/cart';

  private cartCountSubject = new BehaviorSubject<number>(0);
  cartCount$ = this.cartCountSubject.asObservable();
  
  constructor(private http: HttpClient) { 
    // Initialize the cart count from local storage if available
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    this.cartCountSubject.next(cartItems.length);
  }

  // Update cart count with a new count value
  updateCartCount(count: number) {
    this.cartCountSubject.next(count);
  }

  // Get cart items from the behavior subject
  tgetCartItems(): Observable<any[]> {
    return this.cartItems.asObservable();
  }

  // Add item to cart
  addToCart(cartItem: any): Observable<any> {
    const currentCart = this.cartItems.value;
    this.cartItems.next([...currentCart, cartItem]);
    return this.http.post(`${this.baseUrl}`, cartItem);
  }

  // Get cart items by user ID
  getCartItems(userId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/${userId}`);
  }

  // Remove item from cart by cart ID
  removeFromCart(cartId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${cartId}`);
  }

  // Update cart item by cart ID
  updateCartItem(cartId: number, updatedItem: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/${cartId}`, updatedItem);
  }

  // Place an order
  placeOrder(orderData: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/orders`, orderData);
  }

  // Get cart count from API by user ID
  getCartCount(userId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/count/${userId}`);
  }
  
  // Set cart count in BehaviorSubject
  setCartCount(count: number) {
    this.cartCountSubject.next(count);
  }

  // Fetch cart count from API and update BehaviorSubject
  updateCartCountFromAPI(userId: number) {
    this.getCartCount(userId).subscribe(
      (data) => {
        this.setCartCount(data.cart_count); // Update the cart count in the service
      },
      (error) => {
        console.error('Error fetching cart count', error);
      }
    );
  }
}

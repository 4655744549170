// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  baseUrl: 'https://admin.theeasttaste.com:1116/',
  image_path: 'https://admin.theeasttaste.com:1119/',
  receipt_path: 'https://admin.theeasttaste.com:1116/',
  stripekey: 'pk_live_51PxCOjGMDDkg21iqrNTIBEyLYEAAklNAIcMkK9jrUHTQqnjERs4KoXcdv8bDMcWuiIk8tBT4TIJqZFdau132Zec00014F21ZQa',
  firebase: {

    apiKey: "AIzaSyDhqAP-GrRSs0EeCJAHTvA9OuW_Ml0lShc",
    authDomain: "easttaste-34014.firebaseapp.com",
    databaseURL: "https://easttaste-34014-default-rtdb.firebaseio.com",
    projectId: "easttaste-34014",
    storageBucket: "easttaste-34014.appspot.com",
    messagingSenderId: "91522831735",
    appId: "1:91522831735:web:14999a6396a5aefbecbd72",
    measurementId: "G-VV19Z93BZ4"

    /* apiKey: "AIzaSyAz0e_-2v8VeAzHQl7PXz45XG2TsZ_G600",
    authDomain: "east-taste-8844b.firebaseapp.com",
    projectId: "east-taste-8844b",
    storageBucket: "east-taste-8844b.appspot.com",
    messagingSenderId: "404904314103",
    appId: "1:404904314103:web:1c9a67b618beee3ff17ff2",
    measurementId: "G-0W4TRMQEFS" */

  /*  apiKey: "AIzaSyBba5sKFGlYlfuPWTZM20lcE7QGfbGSZ3U",
  authDomain: "easttaste-c2131.firebaseapp.com",
  projectId: "easttaste-c2131",
  storageBucket: "easttaste-c2131.appspot.com",
  messagingSenderId: "1027039067949",
  appId: "1:1027039067949:web:c5d84a67f5394786d71da4",
  measurementId: "G-E9P5G6Y5K6" */
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = environment.baseUrl + 'api/auth';
  private appUrl = environment.baseUrl;
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(this.isAuthenticated());
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  constructor(
    private http: HttpClient,
    private router: Router

  ) {}

  login(credentials: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/login`, credentials);
  }


  navigateTo(url: string, params?, extras?) {
    if (params) {
      this.router.navigate([url, params], extras);
    } else {
      this.router.navigate([url]);
    }
  }

  setAuthenticationStatus(isAuthenticated: boolean): void {
    this.isAuthenticatedSubject.next(isAuthenticated);
  }

  getAuthenticationStatus(): boolean {
    return this.isAuthenticatedSubject.value;
  }
  
  isAuthenticated(): boolean {
    const userId = localStorage.getItem('userId');
    return !!userId;  // Returns true if a valid token exists, false otherwise
  }

  sendOtp(mobileNumber: string): Observable<any> {
    const url = `${this.baseUrl}/send-otp`;
    return this.http.post(url, { mobileNumber });
  }

  verifyOtp(requestId: string, otpCode: string): Observable<any> {
    const url = `${this.baseUrl}/verify-otp`;
    return this.http.post(url, { requestId, otpCode });
  }


   // Register or log in a user with phone and Firebase UID
  register(phone: string, uid: string,device_token:string): Observable<any> {
    const body = { phone, uid,device_token };
    return this.http.post(`${this.baseUrl}/register`, body);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/forgot-password`, { email });
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('userId');
  }

  getToken(): string | null {
    return localStorage.getItem('deviceToken');
  }


  updateProfile(profileData: FormData): Observable<any> {

   
    return this.http.put(`${this.baseUrl}/update-profile`, profileData);
  }

  getUserProfile(): Observable<any> {
    const userId = localStorage.getItem('userId'); // Assuming the userId is stored in localStorage
    return this.http.get(`${this.baseUrl}/profile/${userId}`);
  }
  
  logout(): void {
    localStorage.removeItem('deviceToken');
    localStorage.removeItem('userId');
    this.isAuthenticatedSubject.next(false);
    this.router.navigate(['/sign']);
  }
}

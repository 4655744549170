import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  
    // Using BehaviorSubject to get the current authentication state
    const isAuthenticated = this.authService.getAuthenticationStatus();
  
    if (isAuthenticated) {
      return true;
    } else {
      this.router.navigate(['/sign'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
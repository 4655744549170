import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PublicGuard } from './guards/public.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./pages/secure/secure.module').then(m => m.SecureModule),
    // canActivate: [AuthGuard] // Secure all child pages
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/public/welcome/welcome.module').then(m => m.WelcomePageModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'signin',
    loadChildren: () => import('./pages/public/signin/signin.module').then(m => m.SigninPageModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/public/signup/signup.module').then(m => m.SignupPageModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/public/password-reset/password-reset.module').then(m => m.PasswordResetPageModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'category-items/:categoryId',
    loadChildren: () => import('./pages/secure/products/category-items/category-items.module').then(m => m.CategoryItemsPageModule)
  },
    // Route for My Orders Page
    {
      path: 'my-orders',
      loadChildren: () => import('./pages/secure/my-orders/my-orders.module').then(m => m.MyOrdersPageModule),
      canActivate: [AuthGuard]
    },
    
    // Route for Order Summary Page (using dynamic orderId parameter)
    {
      path: 'order-summary',
      loadChildren: () => import('./pages/secure/order-summary/order-summary.module').then(m => m.OrderSummaryPageModule),
      canActivate: [AuthGuard]
    },
     // Route for Order Summary Page (using dynamic orderId parameter)
     {
      path: 'order-confirmation/:orderId',
      loadChildren: () => import('./pages/secure/order-confirmation/order-confirmation.module').then(m => m.OrderConfirmationPageModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'order-details/:orderId',
      loadChildren: () => import('./pages/secure/order-details/order-details.module').then(m => m.OrderDetailsPageModule),
      canActivate: [AuthGuard]
    },
    
    {
      path: 'upload-receipt/:orderId',
      loadChildren: () => import('./pages/secure/upload-receipt/upload-receipt.module').then(m => m.UploadReceiptPageModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'update-profile',
      loadChildren: () => import('./pages/secure/update-profile/update-profile.module').then(m => m.UpdateProfilePageModule),
      canActivate: [AuthGuard]
    },
    // Wildcard route for a 404 page (optional but good practice)
    { path: '**', redirectTo: 'home', pathMatch: 'full' }
 
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
